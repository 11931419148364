import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import RemoteHtml from "./remote-html";
import CenterFrame from "./center-frame";

const windowGlobal = typeof window !== "undefined" && window;

const StaticHtml = (props) => {
  useEffect(() => {
    const { scrollTop } = props;
    if (scrollTop) {
      windowGlobal.scrollTo(0, 0);
    }
  });

  const { url, className, children, centered } = props;

  if (centered) {
    return (
      <div>
        <CenterFrame className={className}>
          {url ? <RemoteHtml name={url} /> : children}
        </CenterFrame>
      </div>
    );
  }

  return (
    <div className={className}>
      {url ? <RemoteHtml name={url} /> : children}
    </div>
  );
};

StaticHtml.propTypes = {
  url: PropTypes.string,
  className: PropTypes.string.isRequired,
  children: PropTypes.node,
  scrollTop: PropTypes.bool,
  centered: PropTypes.bool,
};

StaticHtml.defaultProps = {
  children: null,
  scrollTop: false,
  url: null,
  centered: false,
};

export default styled(StaticHtml)`
  section {
    background-color: white;
    padding: 30px;
    margin: 50px -29px 10px -29px;
  }
  .rows {
    display: grid;
    grid-template-columns: 50% 50%;
    @media only screen and (max-width: ${({ theme }) => theme.contentWidth}) {
      // mobile
      grid-template-columns: 100%;
    }
    & > * {
      &:not(:last-child) {
        padding-right: 20px;
      }
    }
  }
  ul {
    padding-left: 20px;
    margin: 0.75em 0;
    text-align: justify;
    font-size: 1.2rem;
    font-weight: 200;
    li {
      list-style: disc;
      margin-bottom: 20px;
      line-height: 1.2em;
    }
  }
  ol {
    padding-left: 20px;
    margin: 0.75em 0;
    font-size: 1.2rem;
    font-weight: 200;
    li {
      list-style: decimal;
      margin-bottom: 20px;
      line-height: 1.2em;
    }
  }
  p {
    margin: 0.75em 0;
    line-height: 1.2em;
    text-align: justify;
    font-size: 1.2rem;
    font-weight: 200;
  }
  h1,
  h2,
  h3,
  h4 {
    font-weight: bold;
    margin: 1em 0;
  }
  h1 {
    font-size: 2em;
    color: #312b43;
    margin-top: 50px;
  }
  h2 {
    font-size: 1.6em;
    color: #312b43;
  }
  h3 {
    font-size: 1.4em;
    color: #312b43;
  }
  h4 {
    font-size: 1.2em;
    color: #178b7c;
    margin-left: 20px;
  }
  strong,
  b {
    font-weight: bold;
  }
  a {
    font-weight: 400;
    color: ${({ theme }) => theme.altBorder};
  }
  table {
    width: 100%;
    tr {
      background-color: white;
    }
    th {
      text-align: left;
      font-weight: bold;
      border-bottom: 1px solid ${({ theme }) => theme.altColor};
    }
    th,
    td {
      padding: 15px;
    }
  }
`;
