import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import styled from "styled-components";

import DefaultTemplate from "../templates/default-template";
import StaticHtml from "../components/common/static-html";
import { useLocationInitialization } from "../utils/hooks";
import { createLocaleLink } from "../utils/locale";

const UseOfCookiesStyle = styled.div`
    .remote-html {
        padding: 60px 20px;
    }
`;

const UseOfCookies = ({ data, location, pageContext: { locale } }) => {
    const [i18nData, setI18nData] = useState(null);
    const [url, setUrl] = useState();

    useLocationInitialization(location);

    useEffect(() => {
        setUrl(createLocaleLink(locale, "docs/use-of-cookies"));
        setI18nData(data.allDatoCmsI18N.nodes[0]);
    }, [data]);

    return (
        <>
            {i18nData ? (
                <DefaultTemplate
                    i18nData={i18nData}
                    locale={locale}
                    pathname={location.pathname}
                    title={i18nData.footerUseOfCookies}
                >
                    <UseOfCookiesStyle>
                        <StaticHtml scrollTop centered url={url} title="Use of cookies" />
                    </UseOfCookiesStyle>
                </DefaultTemplate>
            ) : null}
        </>
    );
};

UseOfCookies.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.any.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }).isRequired,
    pageContext: PropTypes.shape({
        locale: PropTypes.string,
    }).isRequired,
};

export default UseOfCookies;

export const query = graphql`
    query UseOfCookies($locale: String) {
        allDatoCmsI18N(filter: { locale: { eq: $locale } }) {
            nodes {
                ...HeaderData
                ...FooterData
                ...CookieBannerData
            }
        }
    }
`;
