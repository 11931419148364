import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const windowGlobal = typeof window !== "undefined" && window;

async function loadHtml(name) {
  const remote = await fetch(`${name}.html`);

  if (remote.status !== 200) {
    return `<h1>Missing resource: ${name}</h1>`;
  }
  return remote.text();
}

const RemoteHtml = ({ className, name }) => {
  const [content, setContent] = useState("");

  useEffect(async () => {
    const updatedContent = (await loadHtml(name)).replace(
      /__STATIC_ROOT__/g,
      windowGlobal.STATIC_ROOT
    );
    setContent(updatedContent);
  }, []);

  return (
    <div
      className={`${className} remote-html`}
      /* eslint-disable-next-line react/no-danger */
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

RemoteHtml.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

RemoteHtml.defaultProps = {
  className: "",
};

export default RemoteHtml;
